<template>
<div class="forget-main">
  <el-steps :active="active" finish-status="success" simple class="step-nav">
    <el-step title="1，填写账号"></el-step>
    <el-step title="2，验证身份"></el-step>
    <el-step title="3，设置新密码"></el-step>
    <el-step title="4，完成"></el-step>
  </el-steps>
  <div class="step-content" v-show="active == 0">
    <p data-v-47e9a266="" style="color: rgb(245, 108, 108); margin-bottom: 30px; font-size: 16px;text-align: center;">温馨提示：如果您是市级专家，忘记密码请联系技术客服 010-57182596</p>
    <el-form style="width: 490px" label-position="right" label-width="140px" class="form">
      <el-form-item label="用户名：">
        <el-input v-model="username" placeholder="输入用户名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 120px;" type="primary" @click="submitForm1" :loading="submitLoading" :disabled="canSubmit">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="step-content" v-show="active == 1">
    <p data-v-47e9a266="" style="color: rgb(245, 108, 108); margin-bottom: 30px; font-size: 16px;text-align: center;">正在为您的手机号【{{mobile}}】找回密码</p>
    <el-form style="width: 490px" label-position="right" label-width="140px" class="form">
      <el-form-item label="图形验证码：">
        <el-input style="float:left;width:240px;margin-right:20px" clearable placeholder="请输入图形验证码" v-model="code"></el-input>
        <div class="sms-btn" @click="getCode">
          <el-image class="code" :src="captcha" fit="cover"></el-image>
        </div>
      </el-form-item>
      <el-form-item label="短信验证码：">
        <el-input style="float:left;width:240px;margin-right:20px" clearable placeholder="请输入短信验证码" v-model="sms"></el-input>
        <el-button class="sms-btn" :disabled="!!smsCount" @click="sendSms" :type="smsCount ? '' : 'success'">
          {{ smsCount ? smsCount + "s后重试" : smsBtnText }}
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 120px;" type="primary" @click="submitForm2" :loading="submitLoading" :disabled="canSubmit2">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="step-content" v-show="active == 2">
    <el-form style="width: 490px" label-position="right" ref="ruleForm" :rules="rules" label-width="140px" class="form">
      <el-form-item label="设置新密码：" prop="pwd">
        <el-input placeholder="6-20位字母、数字或符号的组合" v-model="pwd" show-password></el-input>
      </el-form-item>
      <el-form-item label="确认新密码：" prop="rePwd">
        <el-input placeholder="请再次输入新密码" v-model="rePwd" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 120px;" type="primary" @click="submitForm3" :loading="submitLoading" :disabled="canSubmit3">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="step-content" v-show="active == 3">
    <el-result icon="success" title="设置成功" subTitle="请牢记新的密码">
      <template slot="extra">
        <el-button type="primary" size="medium" @click="login">去登录</el-button>
      </template>
    </el-result>
  </div>
</div>
</template>

<script>
export default {
  name: "forget",
  data() {
    var validatePass = (rule, value, callback) => {
      if (this.pwd.length < 6) {
        callback(new Error("密码不能少于6位!"));
      }
      if (this.rePwd !== "") {
        this.$refs.ruleForm.validateField("rePwd");
      }
      callback();
    };
    var validatePass2 = (rule, value, callback) => {
      if (this.rePwd !== this.pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      id: '',
      stepList: ["1，填写手机号", "2，验证身份", "3，设置新密码", "4，完成"],
      active: 0,
      username: '',
      submitLoading: false,
      mobile: "",
      code: "",
      pwd: "",
      rePwd: "",
      code: '',
      captcha: '',
      client: '',
      sms: "",
      smsCount: 0,
      smsBtnText: "发送验证码",
      smsTimer: null,
      rules: {
        pwd: [{
          validator: validatePass,
          trigger: "blur"
        }, ],
        rePwd: [{
          validator: validatePass2,
          trigger: "blur"
        }, ],
      }
    };
  },
  mounted() {
    this.getCode();
  },
  computed: {
    canSubmit() {
      return !(
        this.username)
    },
    canSubmit2() {
      return !(
        this.sms &&
        this.code)
    },
    canSubmit3() {
      return !(this.pwd && this.rePwd)
    },
  },
  components: {},
  methods: {
    back() {
      this.active = 0;
      this.smsCount = 0;
      this.code = '';
      this.getCode();
      this.smsTimer = '';
      clearInterval(this.smsTimer);
    },
    async sendSms() {
      let res = await this.$api({
        method: "post",
        url: "/api/auth/forgetpwd",
        data: {
          phone: this.mobile,
          captcha: this.code,
          client: this.client
        }
      });
      if (res.code == 0) {
        this.$message.success('发送成功');
        this.smsCount = 60;
        this.smsTimer = setInterval(() => {
          this.smsCount--;
          if (this.smsCount == 0) {
            clearInterval(this.smsTimer);
            this.smsBtnText = "重发验证码";
          }
        }, 1000);
      } else {
        this.getCode();
        this.code = '';
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }

    },
    async submitForm1() {
      let res = await this.$api({
        method: "post",
        url: "/api/auth/forgetphone",
        data: {
          username: this.username,
        }
      });
      if (res.code == 0) {
        this.mobile = res.data.phone;
        this.id = res.data.id;
        this.active += 1;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async submitForm2() {
      let res = await this.$api({
        method: "post",
        url: "/api/auth/forcheckcode",
        data: {
          id: this.id,
          code: this.sms,
          phone: this.mobile
        }
      });
      if (res.code == 0) {
        this.$message.success('验证成功');
        this.active += 1;
      } else {
        this.getCode();
        this.code = '';
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async submitForm3() {
      let res = await this.$api({
        method: "post",
        url: "/api/auth/forpwdsave",
        data: {
          id: this.id,
          newpswd: this.pwd,
          repswd: this.rePwd
        }
      });
      if (res.code == 0) {
        this.active += 1;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async getCode() {
      let res = await this.$api({
        method: "get",
        url: "/api/img_code",
      });
      console.log(res);
      if (res.code == 0) {
        this.captcha = res.data.captcha
        this.client = res.data.client
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    login() {
      this.$router.push("/user/login")
    },
    nextStep() {
      if (this.step == 3) {
        this.step = 0;
      } else {
        this.step += 1;
      }
    },
  },
};
</script>

<style lang="scss">
.forget-main {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .step-content {
    min-height: 100vh;
  }

  .step-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px auto;
    width: 1000px;
  }

  .step-nav_item {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .step-nav_active {
    color: red;
  }

  .sms-btn {
    width: 120px;
    flex: 0 0 120px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>

<style lang="scss">
.forget-main {
  .el-form-item__content {
    display: flex;
  }

  .el-steps--simple {
    padding: 20px 100px;
  }

  .el-form-item__label {
    font-weight: bold;
    font-size: 16px;
  }

  .sms-btn .el-image {
    width: 120px;
    height: 40px;
    border-radius: 3px;
  }
}
</style>
